import { useQuery } from '@apollo/client'
import { GET_TASKS_BY_GROUP } from 'gql/tasks.query'
import { IUseFetchTasksByGroupProps, IUseFetchTasksResponse } from './getTasksService.interface'

export const useFetchTasksByGroup = ({
  searchValue,
  currentPage = 1,
  perPage = 10,
  filterBy,
  groupId,
}: IUseFetchTasksByGroupProps): IUseFetchTasksResponse => {
  const { data, loading, error, refetch, fetchMore } = useQuery(GET_TASKS_BY_GROUP, {
    variables: {
      searchValue,
      currentPage,
      perPage,
      filterBy,
      groupId,
    },
    skip: !groupId,
  })
  const errorMessage = error ? error?.message.split(':')[1] : ''
  const { tasks = [], totalPages = 1 } = (data && data.getTasksByGroupId) || {}

  return {
    tasks,
    totalPages,
    loading,
    error: errorMessage,
    refetch,
    fetchMore: variables => fetchMore({ variables }),
  }
}
