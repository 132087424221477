import React, { ChangeEvent, useState, useCallback, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import IconButton from '@mui/material/IconButton'
import CourseList from 'pages/courses/CourseList'
import SearchRoundedIcon from '@mui/icons-material/SearchRounded'
import Drawer from 'components/common/Drawer'
import { Input } from 'components/common/TextInput'
import { ToolbarItem } from 'components/common/Toolbar'
import { Grid } from 'components/common/Cards/styled-components'
import { useFetchCourses } from 'hooks/course/useCourses'
import FilterDrawer from 'pages/courses/FilterDrawer/FilterDrawer'
import { Wrapper, ToolBarWrapper } from './styled-components'
import { SplashScreen } from 'components/common'
import PaginationV2 from 'components/common/PaginationV2'
import { UpperCaseFilterableFieldType } from 'enums/filterEnum'
import { IFilterQuery } from './group.interface'
import { ICourseFilter } from 'interfaces/courses/courses.interface'
import { IGroupsFilterDrawer } from 'hooks/course/addCourseDrawer/addGroupInCourseDrawer/addGroupInCourseDrawer.interface'
import { FilterOptionsType } from 'pages/courses/FilterDrawer/filterDrawer.interface'
import MyLoader from 'loading/loading'
import { debounce } from 'lodash'

const GroupCourses: React.FC<{ groupId: string }> = ({ groupId }) => {
  const defaultFilter: FilterOptionsType = {
    groups: {
      type: UpperCaseFilterableFieldType.NESTED_ARRAY_IN,
      value: [groupId],
      nestedField: 'GROUP_ID',
    },
  }
  const { t } = useTranslation()
  const [filterIsOpen, setFilterIsOpen] = useState(false)
  const [searchText, setSearchText] = useState<string>('')
  const [isSearchType, setIsSearchType] = useState<boolean>(false)
  const [initialLoad, setInitialLoad] = useState(true)
  const [filterData, setFilterData] = useState<IGroupsFilterDrawer>({
    values: defaultFilter,
    filterOptions: {},
  })
  const [filterQuery, setFilterQuery] = useState<IFilterQuery | object>({})
  const [currentPage, setCurrentPage] = useState<number>(1)
  const [perPage] = useState<number>(10)

  const { courses, loading, refetch, error } = useFetchCourses(
    {
      ...defaultFilter,
      ...filterQuery,
      ...filterData.values,
    } as ICourseFilter,
    currentPage,
    perPage,
  )

  useEffect(() => {
    if (!loading) {
      setInitialLoad(false)
    }
  }, [loading])

  const resetFilter = (): void => {
    setFilterQuery({})
    setFilterData({} as IGroupsFilterDrawer)
    setFilterIsOpen(false)
    setSearchText('')
  }

  const onSearchSubmit = (e: React.FormEvent): void => {
    e.preventDefault()
    const formValue = {
      name: { type: UpperCaseFilterableFieldType.MATCH, value: searchText },
    }
    setCurrentPage(1)
    setFilterQuery({
      ...filterQuery,
      ...formValue,
      ...filterData.filterOptions,
    })
    refetch({
      currentPage: 1,
      perPage,
      filter: filterQuery,
    })
  }

  const debouncedSearch = useCallback(
    debounce((value: string) => {
      const formValue = {
        name: { type: UpperCaseFilterableFieldType.MATCH, value },
      }
      const updatedFilterQuery = {
        ...filterQuery,
        ...formValue,
        ...filterData.filterOptions,
      }
      setFilterQuery(updatedFilterQuery)
      refetch({
        currentPage: 1,
        perPage,
        filter: updatedFilterQuery,
      })
      setIsSearchType(value !== '')
    }, 500),
    [filterQuery, filterData.filterOptions, perPage, refetch],
  )

  const handleSearchChange = (e: ChangeEvent<HTMLInputElement>): void => {
    const value = e.target.value
    setSearchText(value)
    setCurrentPage(1)
    debouncedSearch(value)
    setIsSearchType(true)
  }

  const handlePaginationClick = (_: ChangeEvent<unknown>, value: number): void => {
    if (value !== currentPage) {
      setCurrentPage(value)
    }
  }

  return (
    <>
      {courses?.data?.length === 0 && !loading && !isSearchType && searchText === '' ? (
        <SplashScreen
          title={t('courses_layout.no_courses')}
          subTitle={t('courses_layout.create_courses')}
          marginTop={20}
          textMarginTop='40px'
          isSearchOrFilter={true}
        />
      ) : (
        <Wrapper>
          {!initialLoad && (
            <ToolBarWrapper>
              <ToolbarItem>
                <form onSubmit={onSearchSubmit}>
                  <Input
                    label={t('general.search_placeholder')}
                    type='text'
                    size='small'
                    value={searchText}
                    onChange={handleSearchChange}
                    icon={
                      <IconButton onClick={onSearchSubmit}>
                        <SearchRoundedIcon />
                      </IconButton>
                    }
                  />
                </form>
              </ToolbarItem>
            </ToolBarWrapper>
          )}
          <div>
            {loading ? (
              <MyLoader />
            ) : (
              courses && (
                <>
                  {courses.data.length === 0 ? (
                    <SplashScreen
                      title={t('general.no_results_found')}
                      subTitle={t('general.try_adjusting_your_search_or_filter')}
                      textMarginTop='40px'
                      isSearchOrFilter={true}
                    />
                  ) : (
                    <Grid className={'card'} style={{ position: 'relative' }}>
                      {error && error}
                      <CourseList
                        data={courses.data}
                        gridStyle={'card'}
                        groupId={groupId}
                        $canOpen
                        isGridView
                      />
                    </Grid>
                  )}
                  {courses.data.length > 0 && (
                    <div
                      style={{
                        marginTop: 20,
                        display: 'flex',
                        justifyContent: 'center',
                      }}
                    >
                      <PaginationV2
                        currentPage={courses ? courses.currentPage : 1}
                        totalPages={courses ? courses.totalPages : 0}
                        handleChange={handlePaginationClick}
                      />
                    </div>
                  )}
                </>
              )
            )}
          </div>

          <Drawer opened={filterIsOpen} toggleDrawer={(): void => undefined} totalWidth='700px'>
            <FilterDrawer
              filterData={filterData}
              setFilterData={setFilterData}
              refetchCourse={refetch}
              resetFilter={resetFilter}
              onClose={(): void => setFilterIsOpen(false)}
              isCooursePrivacyHidden={true}
              setCurrentPage={setCurrentPage}
              t={t}
            />
          </Drawer>
        </Wrapper>
      )}
    </>
  )
}

export default GroupCourses
