import { styled } from 'styled-components'

export const BooksGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(204px, 1fr));
  gap: 20px;
  margin-top: 20px;
  margin-bottom: 20px;
`

export const ToolbarContainer = styled.div`
  width: 100%;
  display: flex;
  box-sizing: border-box;
  padding: 20px 0;
  justify-content: space-between;
`

export const BooksContainer = styled.div`
  flex-grow: 1;
  overflow-y: auto;
  padding: 0 20px 20px;
`

export const TasksHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
  padding: 10px 20px 0;
`

export const DrawerContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: calc(100% - 105px);
  overflow: hidden;
`

export const FilterHeader = styled.header<{ width?: string }>`
  padding: 10px 20px;
  width: ${(props): string => props.width || 'auto'};
`

export const FilterTitle = styled.h2`
  font-size: 20px;
  font-weight: 450;
`

export const TasksContainer = styled.div`
  flex-grow: 1;
  overflow-y: auto;
  padding: 0 20px 20px;
`

export const PaginationContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
`
export const StyledTableContainer = styled.div`
  th:first-child {
    width: 10px;
  }
`
