import React, { ChangeEvent, useCallback, useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router'
import { DrawerEventEmitter } from 'helpers/drawer'
import { debounce } from 'lodash'
import { useFetchModules, PER_PAGE as perPage } from 'hooks/modules/useModules'
import { useDeleteModuleService } from 'hooks/modules/useDeleteModuleService'
import { useSwal } from 'hooks/useSwal'
import { useUserValue } from 'context/UserContext'
import { useTranslation } from 'react-i18next'
import { LowerCaseFilterableFieldType } from 'enums/filterEnum'
import { IFilterQueryType } from 'components/common/FilterInterface/filter.interface'
import { actionConfig, modulesListConfig } from 'pages/modules/gridConfig'
import { IModule } from 'pages/modules/ModulesList.interface'
import { IFetchModulesReturnType, IUseModulesLayoutReturnType } from './useModuleLayout.interface'
import { IFilterData } from 'pages/modules/FilterDrawer/filterDrawer.interface'
import {
  IColumnItem,
  IVideo,
} from 'pages/modules/ModuleDetail/ModuleTopicsGridV2/ModuleTopic.interface'
import { checkIfFiltered } from 'utils/FilterIconHandler'

export interface IPdfEvent {
  attachment: string
}

const useModulesLayout = (): IUseModulesLayoutReturnType => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const [state] = useUserValue()

  const [filterIsOpen, setFilterIsOpen] = useState(false)
  const stateRef = useRef(state)
  const [videoModal, setVideoModal] = useState({
    modal: false,
    data: '',
    subtitle: '',
  })
  const [filterData, setFilterData] = useState<IFilterData>({})
  const [searchText, setSearchText] = useState<string>('')
  const [filterQuery, setFilterQuery] = useState<IFilterQueryType>({})
  const [currentPage, setCurrentPage] = useState<number>(1)
  const [selectedItem, setSelectedItem] = useState<Record<number, string[]>>({})
  const [selectAll, setSelectAll] = useState<Record<number, boolean>>({})
  const [isSearchType, setIsSearchType] = useState<boolean>(false)
  const [isFiltered, setIsFiltered] = useState(false)
  const [isCardView, setIsCardView] = useState(true)

  const redirectToModule = (moduleId: string): void => {
    navigate(`/modules/${moduleId}`)
  }

  const companyId = state.selectedCompany?.id || null

  const { deleteModule, deletedModule } = useDeleteModuleService()
  const { fireSwal } = useSwal()

  const { modules, refetch, loading }: IFetchModulesReturnType = useFetchModules({
    companyId: {
      type: LowerCaseFilterableFieldType.EXACT,
      value: state.selectedCompany?.id,
    },
  })

  const selectItem = (id: string): void => {
    const selectedItemsOnPage = selectedItem[currentPage] || []
    const selectedIndex = selectedItemsOnPage.indexOf(id)
    let newSelected: string[] = []

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selectedItemsOnPage, id)
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selectedItemsOnPage.slice(1))
      setSelectAll({
        ...selectAll,
        [currentPage]: false,
      })
    } else if (selectedIndex === selectedItemsOnPage.length - 1) {
      newSelected = newSelected.concat(selectedItemsOnPage.slice(0, -1))
      setSelectAll({
        ...selectAll,
        [currentPage]: false,
      })
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selectedItemsOnPage.slice(0, selectedIndex),
        selectedItemsOnPage.slice(selectedIndex + 1),
      )
      setSelectAll({
        ...selectAll,
        [currentPage]: false,
      })
    }

    setSelectedItem({
      ...selectedItem,
      [currentPage]: newSelected,
    })
  }

  const handleVideoModal = (data: IModule): void => {
    if (!data.video) return

    setVideoModal({
      ...videoModal,
      modal: !videoModal.modal,
      subtitle: !videoModal.modal ? data.subtitle : '',
      data: !videoModal.modal ? (data.video as IVideo).links[0].url : '',
    })
  }

  const resetFilter = (): void => {
    setFilterData({})
    refetch({
      filter: {
        companyId: {
          type: LowerCaseFilterableFieldType.EXACT,
          value: state.selectedCompany?.id,
        },
      },
      currentPage: 1,
      perPage,
    })
    setFilterIsOpen(false)
    setFilterQuery({})
    setSearchText('')
  }

  const filterDrawer = (): void => {
    setFilterIsOpen(!filterIsOpen)
  }

  useEffect(() => {
    stateRef.current = state
  }, [state])

  useEffect(() => {
    const filtered = checkIfFiltered(filterData)
    setIsFiltered(filtered)
  }, [filterData])

  const debouncedRefetch = useRef(
    debounce((newSearchText: string) => {
      const currentSelectedCompanyId = stateRef.current.selectedCompany?.id
      const searchValue = {
        type: LowerCaseFilterableFieldType.MATCH,
        value: newSearchText,
      }
      setFilterQuery((prevFilterQuery: IFilterQueryType) => ({
        ...prevFilterQuery,
        name: searchValue,
      }))
      refetch({
        filter: {
          companyId: {
            type: LowerCaseFilterableFieldType.EXACT,
            value: currentSelectedCompanyId,
          },
          name: searchValue,
        },
        currentPage: 1,
        perPage,
      })
      setIsSearchType(newSearchText !== '')
    }, 500),
  ).current

  const handleSearchChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const newSearchText = e.target.value
      setSearchText(newSearchText)
      debouncedRefetch(newSearchText)
      setIsSearchType(true)
    },
    [debouncedRefetch],
  )

  const onSearchSubmit = useCallback(
    (e: React.FormEvent) => {
      e.preventDefault()
      debouncedRefetch(searchText)
    },
    [searchText, debouncedRefetch],
  )

  const downloadPdf = (e: IPdfEvent): void => {
    if (!e.attachment) return
    window.open(e.attachment, '_blank')
  }

  const openDrawer = (id: string): void => {
    DrawerEventEmitter.emit('openDrawer', 'editModuleDrawer', true, { id })
  }

  const handelOpenEditDrawer = (data: IModule): void => {
    if (data.courses && data.courses.length > 0) {
      fireSwal({
        title: t('popups.edit_module'),
        confirmText: t('popups.ok'),
        onConfirm: () => openDrawer(data.id),
      })
      return
    }

    openDrawer(data.id)
  }

  const handleDeleteClick = (
    field: IModule | string[],
    event?: React.MouseEvent<HTMLButtonElement>,
  ): void => {
    if (event) {
      fireSwal({
        title: t('popups.delete_module_many'),
        confirmText: t('popups.confirm_delete'),
        onConfirm: () => {
          deleteModule(field as string[])
          setSelectedItem({})
          setSelectAll({})
        },
      })

      return
    }

    if ((field as IModule).courses.length !== 0) {
      fireSwal({
        title: t('popups.delete_module_single'),
        confirmText: t('popups.confirm_delete'),
        onConfirm: () => {
          deleteModule([(field as IModule).id as string])
          setSelectedItem({})
          setSelectAll({})
        },
      })
    } else {
      fireSwal({
        title: t('popups.delete_module_single'),
        confirmText: t('popups.confirm_delete'),
        onConfirm: () => {
          deleteModule([(field as IModule).id as string])
          setSelectedItem({})
          setSelectAll({})
        },
      })
    }
  }

  const handlePaginationClick = (event: ChangeEvent<unknown>, value: number): void => {
    if (value !== currentPage) {
      refetch({
        filter: {
          companyId: {
            type: LowerCaseFilterableFieldType.EXACT,
            value: state.selectedCompany?.id,
          },
          ...filterQuery,
        },
        currentPage: value,
        perPage,
      })
      setCurrentPage(value)
    }
  }

  useEffect(() => {
    if (deletedModule) {
      refetch()
    }
  }, [deletedModule])

  const modulesListConfiguration = modulesListConfig(t)

  const actionHandler = (
    event: string,
    field: string | IModule | IPdfEvent | IColumnItem,
  ): void | (() => void) => {
    switch (event) {
      case 'view':
        navigate(`/modules/${field as string}`)
        break
      case 'edit':
        return handelOpenEditDrawer(field as IModule)

      case 'delete':
        handleDeleteClick(field as IModule)
        break
      case 'pdf':
        return downloadPdf(field as IPdfEvent)
      default:
        return () => console.log('null')
    }
  }

  const actions = actionConfig(actionHandler, state.userPermission, t, isCardView)

  return {
    state,
    refetch,
    filterQuery,
    setSelectedItem,
    modules,
    companyId,
    searchText,
    t,
    setSelectAll,
    filterData,
    resetFilter,
    filterDrawer,
    onSearchSubmit,
    handleSearchChange,
    selectItem,
    handleVideoModal,
    actions,
    handlePaginationClick,
    videoModal,
    setVideoModal,
    filterIsOpen,
    selectedItem,
    currentPage,
    setFilterData,
    setFilterQuery,
    isSearchType,
    isFiltered,
    loading,
    isCardView,
    setIsCardView,
    modulesListConfiguration,
    redirectToModule,
  }
}

export default useModulesLayout
