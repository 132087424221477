import { ChangeEvent, useEffect, useState } from 'react'
import { FetchResult, useMutation } from '@apollo/client'
import { CREATE_TASK_MUTATION } from 'gql/tasks.query'
// import { useFetchModuleOptions } from 'hooks/modules/useModules'
import { useUpdateTask } from 'services/tasks/updateTaskService'
import {
  IGroup,
  ICreateTaskResponse,
  ICreateTaskVariables,
  ITag,
  // IMappedOptions,
  IUseTaskDrawerReturn,
  FormValues,
  IUpdateTaskResponse,
  IUploadedFileBrief,
} from './useTaskDrawer.interface'
import Swal from 'sweetalert2'
import { useTranslation } from 'react-i18next'
import useSnackbarAlert from 'hooks/useSnackbar'
import { useFetchTaskById } from 'services/tasks/getTaskByIdService'

import { useFetchTagsByCompanyId } from 'services/tasks/getTagsByCompanyId'
import { ITags } from 'components/common/Tags/tags.interface'
import {
  useGenerateUploadVideoLinks,
  useUploadAudioService,
  useUploadFile,
} from 'hooks/helpers/useHelpersService'
import { fileTypeConstant, IAttachment } from 'interfaces/common'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import axios, { AxiosResponse } from 'axios'
import { IVideo } from 'pages/modules/ModuleDetail/ModuleTopicsGridV2/ModuleTopic.interface'
import { IGenerateUploadVideoLinksMutationResponse } from 'services/helperService/helperService'
import { FileTypes, IAudioFile } from 'hooks/lessons/useAddLessonDrawer/useAddLesson.interface'
import { ITaskFormCommonFields } from './useTaskLayout.interface'
import { ITask } from 'services/tasks/getTasksService.interface'
// import { IFilterQueryType } from 'components/common/FilterInterface/filter.interface'
// import { LowerCaseFilterableFieldType } from 'enums/filterEnum'
// import { ISelectDropDownOption } from 'components/common/SelectDropDown/selectDropDown.interface'

export const useTaskDrawer = (
  companyId: string | null,
  setDrawerOpen: (open: boolean) => void,
  onTaskAdded: () => void,
  taskId?: string,
  setTaskId?: (taskId: string | null) => void,
): IUseTaskDrawerReturn => {
  const { t } = useTranslation()
  const { task } = useFetchTaskById(taskId)
  const isUpdating = !!taskId
  const { setSnackbar } = useSnackbarAlert()
  // const moduleFilter: IFilterQueryType = {
  //   companyId: {
  //     type: LowerCaseFilterableFieldType.EXACT,
  //     value: companyId as string,
  //   },
  // }
  // const { moduleOptions } = useFetchModuleOptions(moduleFilter)
  const [fileTypes, setFileTypes] = useState<FileTypes>('video')
  const [uploadVideoProgress, setUploadVideoProgress] = useState<number>(0)
  const [uploadedFile, setUploadedFile] = useState<IAttachment | IVideo | null | string>(null)
  const [createTask, { loading: createTaskLoading }] = useMutation<
    ICreateTaskResponse,
    ICreateTaskVariables
  >(CREATE_TASK_MUTATION)
  const { updateTask, updating } = useUpdateTask()
  const { generateUploadVideoLinks } = useGenerateUploadVideoLinks()
  const { uploadAudio } = useUploadAudioService()
  const { uploadFile, fileLoading } = useUploadFile()

  const acceptedTypes = [
    'application/pdf',
    'application/xml',
    'text/xml',
    'application/msword',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    'video/*',
    'audio/*',
    'image/*',
  ]

  const uploadVideoService = (
    file: File | IAudioFile,
    url: string,
  ): void | Promise<AxiosResponse<unknown>> => {
    return axios.put(url, file, {
      headers: {
        'Content-Type': file?.type,
      },
      onUploadProgress: ({ total, loaded }) => {
        setUploadVideoProgress((loaded / total) * 100)
      },
    })
  }

  const initialValues: FormValues = {
    name: task?.name || '',
    description: task?.description || '',
    selectedGroups: [] as IGroup[],
    audio: null,
    video: null,
    tags: [],
  }

  const { handleSubmit, setValues, resetForm, setFieldValue, values, touched, errors } = useFormik({
    initialValues,
    validationSchema: Yup.object({
      name: Yup.string().required(t('validations.required')),
      description: Yup.string().required(t('validations.required')),
      selectedGroups: Yup.array().min(1, t('validations.required')),
    }),
    onSubmit: async ({ selectedGroups, name, description }) => {
      try {
        const mappedSelectedGroupIds = selectedGroups.map(group => group.value) as string[]

        const commonFields: ITaskFormCommonFields = {
          name,
          description,
          groupIds: mappedSelectedGroupIds,
          video: null,
          attachment: null,
          audio: null,
          tags: [],
        }

        if (uploadedFile) {
          const { name = '', id = '', type: fileType, link, size } = uploadedFile as IAttachment
          switch (fileTypes) {
            case 'video':
            case 'audio':
              commonFields[fileTypes] = {
                name,
                id,
              }
              break
            case 'attachment':
              commonFields['attachment'] = {
                name,
                fileType,
                link,
                size,
              } as IAttachment
              break
          }
        }

        if (values.tags.length) {
          const mappedTags = values.tags.map((tag: ITags) => {
            return { label: tag.label }
          })

          commonFields['tags'] = mappedTags
        }

        const variables = isUpdating
          ? {
              ...commonFields,
              _id: taskId || '',
            }
          : {
              input: {
                companyId,
                ...commonFields,
              },
            }

        const action = isUpdating ? updateTask : createTask
        const options = isUpdating ? { ...(variables || '') } : { variables, _id: '' }
        const response: IUpdateTaskResponse | FetchResult<ICreateTaskResponse> = await action(
          options as unknown as ITask,
        )
        const taskData = isUpdating
          ? (response as IUpdateTaskResponse).data.updateTask
          : (response as FetchResult<ICreateTaskResponse>)?.data?.createTask

        //if task is created or updated, show success message and refetch the tasks
        if (taskData) {
          setSnackbar({
            message: isUpdating ? t('tasks_layout.onUpdateSuccess') : t('tasks_layout.onSuccess'),
            variant: 'success',
          })

          setDrawerOpen(false)
        } else {
          setSnackbar({ message: t('tasks_layout.onError'), variant: 'error' })
        }

        onTaskAdded()
      } catch (e) {
        console.error('Error creating or updating task:', e)
      }
    },
  })

  const handleFileInputChange = (e: ChangeEvent<HTMLInputElement>): void => {
    const name = e.target.value
    if (uploadedFile) {
      setUploadedFile(prev => ({
        ...(prev as IAttachment),
        name,
      }))
    }
  }
  const clearUploadedFile = (): void => {
    setUploadedFile(null)
  }

  const { data: tagsData } = useFetchTagsByCompanyId(companyId)

  const tagList: ITag[] =
    tagsData?.map(({ label, id }: ITags) => ({
      label,
      value: id || '',
    })) || []

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    clearUploadedFile()
    if (!e.target.files || !e.target.files.length) return
    const file = e.target.files[0]
    if (!file) return
    if (fileTypeConstant.audio.includes(file.type)) {
      generateUploadAudioLink(file)
      setFileTypes('audio')
    } else if (fileTypeConstant.video.includes(file.type)) {
      handleGenerateUploadLink(file)
      setFileTypes('video')
    } else {
      const isImage = fileTypeConstant.image.includes(file.type)
      setFileTypes(isImage ? 'image' : 'attachment')
      uploadFile(file, 'task/attachment', (link: string) => {
        setUploadedFile({
          name: file.name,
          type: file.type,
          size: file.size?.toString(),
          lastModified: file.lastModified,
          link: link,
        })
      })
    }
  }

  const generateUploadAudioLink = (file: File): void => {
    const audioFile: IAudioFile = { fileName: file.name, type: file.type }

    uploadAudio(
      audioFile,
      (arg: string) => uploadVideoService(file, arg),
      (fileId: string, _?: string, authorizedLink?: string) => {
        setUploadedFile({
          name: file.name,
          type: file.type,
          id: fileId,
          size: 0,
          link: authorizedLink,
        })
        setUploadVideoProgress(0)
      },
    )
  }

  const handleGenerateUploadLink = (file: File): void => {
    const videos = [{ fileName: file.name, type: file.type }] as IVideo[]

    generateUploadVideoLinks(
      videos,
      'taskVideo',
      (arg: string) => uploadVideoService(file, arg),
      (args: IGenerateUploadVideoLinksMutationResponse) => {
        setUploadedFile(args.video)
        setUploadVideoProgress(0)
      },
      true,
    )
  }

  const handleCloseDrawer = (): void => {
    Swal.fire({
      title: t('popups.close_popup'),
      text: t('popups.sure'),
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: 'rgb(6, 198, 143)',
      cancelButtonColor: 'rgb(224, 225, 226)',
      confirmButtonText: t('popups.confirm_cancel'),
      cancelButtonText: t('actions.cancel'),
      width: '400px',
    }).then((result: { isConfirmed: boolean }) => {
      if (result.isConfirmed) {
        setDrawerOpen(false)
        resetForm()
        if (setTaskId) setTaskId(null)
      }
    })
  }

  useEffect(() => {
    if (taskId && task) {
      const selectedGroups =
        (task?.groupIds?.map(group => ({
          label: (group as IGroup).name,
          value: (group as IGroup)._id,
        })) as IGroup[]) || []

      let taskTags: ITag[] = []
      if (task.tags) {
        const initialTags =
          task.tags.map((tag: ITags) => ({
            label: tag.label,
            value: tag.id || '',
          })) || []
        taskTags = initialTags
      }

      setValues({
        name: task?.name || '',
        description: task?.description || '',
        selectedGroups,
        audio: createUploadedFileBrief(task.audio as IAttachment),
        video: createUploadedFileBrief(task.video as IAttachment),
        tags: taskTags,
      })

      if (task?.attachment) {
        setUploadedFile(task.attachment)
        const isImage = fileTypeConstant.image.includes(task.attachment.type as string)
        setFileTypes(isImage ? 'image' : 'attachment')
      } else if (task?.video) {
        setUploadedFile(task.video as string)
        setFileTypes('video')
      } else if (task?.audio) {
        setUploadedFile(task.audio)
        setFileTypes('audio')
      }
    }
  }, [task, taskId])

  /**
   * Helper function to avoid code duplication when creating uploaded file brief for task input
   * @param file - IAttachment | null
   * @returns - IUploadedFileBrief | null
   */
  const createUploadedFileBrief = (file: IAttachment | null): IUploadedFileBrief | null => {
    return file ? ({ name: file.name, id: file.id } as IUploadedFileBrief) : null
  }

  // const mappedModules = moduleOptions?.data?.map((module: IMappedOptions) => ({
  //   label: module.name,
  //   value: module.id,
  // })) as ISelectDropDownOption[]

  return {
    errors,
    touched,
    handleSubmit,
    handleFileChange,
    values,
    setFieldValue,
    handleCloseDrawer,
    uploadVideoProgress,
    handleFileInputChange,
    clearUploadedFile,
    fileTypes,
    acceptedTypes,
    uploadedFile,
    tagList,
    isUpdating,
    submitDisabled: fileLoading,
    loading: createTaskLoading || updating,
    // mappedModules,
  }
}
