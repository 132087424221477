import React, { ChangeEvent, useState, useEffect, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { Grid } from 'components/common/Cards/styled-components'
import Pagination from 'components/common/Pagination'
import { ToolbarItem } from 'components/common/Toolbar'
import { Wrapper } from './styled-components'
import IconButton from '@mui/material/IconButton'
import SearchRoundedIcon from '@mui/icons-material/SearchRounded'
import { Input } from 'components/common/TextInput'
import TestItem from 'pages/tests/testItem/TestItem'
import { useNavigate } from 'react-router-dom'
import { IGroup } from 'interfaces/groups'
import { useLazyGetTestAssessmentsByCompanyId } from 'hooks/tests'
import { UpperCaseFilterableFieldType } from 'enums/filterEnum'
import { SplashScreen } from 'components/common'
import MyLoader from 'loading/loading'
import { debounce } from 'lodash'

export const GroupTestAssessments: React.FC<{
  group: IGroup
}> = ({ group }) => {
  const { t } = useTranslation()

  const [currentPage, setCurrentPage] = useState(1)
  const [perPage] = useState(12)
  const [searchText, setSearchText] = useState('')
  const [isSearchType, setIsSearchType] = useState<boolean>(false)
  const navigate = useNavigate()
  const defaultFilter = group.testAssessments?.length
    ? {
        _id: {
          type: UpperCaseFilterableFieldType.ARRAY_IN,
          value: group.testAssessments?.length ? group.testAssessments : null,
        },
      }
    : {}

  const { tests, loading, getTestAssessments } = useLazyGetTestAssessmentsByCompanyId()

  useEffect(() => {
    if (group.testAssessments?.length) {
      getTestAssessments({
        currentPage,
        perPage,
        companyId: group?.company?.id,
        filter: defaultFilter,
      })
    }
  }, [group])

  const handlePaginationClick = (_: ChangeEvent<unknown> | null, value: number): void => {
    if (value !== currentPage) {
      setCurrentPage(value)
    }
  }

  const onSearchSubmit = (e: React.FormEvent): void => {
    e.preventDefault()
    setCurrentPage(1)
    const nameFilter = {
      name: {
        type: UpperCaseFilterableFieldType.MATCH,
        value: searchText,
      },
    }
    getTestAssessments({
      currentPage: 1,
      perPage,
      companyId: group?.company?.id,
      filter: {
        ...defaultFilter,
        ...nameFilter,
      },
    })
  }

  const debouncedSearch = useCallback(
    debounce((value: string) => {
      const nameFilter = {
        name: {
          type: UpperCaseFilterableFieldType.MATCH,
          value,
        },
      }
      getTestAssessments({
        currentPage: 1,
        perPage,
        companyId: group?.company?.id,
        filter: {
          ...defaultFilter,
          ...nameFilter,
        },
      })
      setIsSearchType(value !== '')
    }, 500),
    [defaultFilter, perPage, group, getTestAssessments],
  )

  const onSearchChange = (e: ChangeEvent<HTMLInputElement>): void => {
    const value = e.target.value
    setSearchText(value)
    setCurrentPage(1)
    debouncedSearch(value)
    setIsSearchType(true)
  }

  return (
    <>
      {!loading &&
      !isSearchType &&
      (!group.testAssessments || (!tests?.data?.length && !searchText)) ? (
        <SplashScreen
          title={t('tests_layout.no_tests')}
          subTitle={t('tests_layout.create_tests')}
          marginTop={20}
          textMarginTop='40px'
          isSearchOrFilter={true}
        />
      ) : (
        <>
          <Wrapper>
            <ToolbarItem>
              <form onSubmit={onSearchSubmit}>
                <Input
                  label={t('general.search_placeholder')}
                  type='text'
                  size='small'
                  value={searchText}
                  onChange={onSearchChange}
                  icon={
                    <IconButton onClick={onSearchSubmit}>
                      <SearchRoundedIcon />
                    </IconButton>
                  }
                />
              </form>
            </ToolbarItem>
          </Wrapper>
          <div>
            {loading ? (
              <MyLoader />
            ) : (
              tests && (
                <>
                  {tests.data.length === 0 ? (
                    <SplashScreen
                      title={t('general.no_results_found')}
                      subTitle={t('general.try_adjusting_your_search_or_filter')}
                      textMarginTop='40px'
                      isSearchOrFilter={true}
                    />
                  ) : (
                    <Grid
                      className='card'
                      style={{ position: 'relative', marginTop: '17px' }}
                      cols={4}
                    >
                      {tests.data.map((test, index: number) => (
                        <TestItem
                          key={index}
                          item={test}
                          isFromUser={true}
                          handleClick={({ id: testAssessmentId, companyId }): void =>
                            navigate(`/`, {
                              state: {
                                testAssessmentId,
                                groupId: group.id,
                                companyId,
                              },
                            })
                          }
                        />
                      ))}
                    </Grid>
                  )}
                  {tests.totalPages > 1 && (
                    <div
                      style={{
                        marginTop: 20,
                        display: 'flex',
                        justifyContent: 'center',
                      }}
                    >
                      <Pagination
                        currentPage={tests.currentPage || 1}
                        totalPages={tests.totalPages || 0}
                        handleChange={handlePaginationClick}
                      />
                    </div>
                  )}
                </>
              )
            )}
          </div>
        </>
      )}
    </>
  )
}
