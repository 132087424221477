import { ChangeEvent, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { IGroup } from 'interfaces/groups'
import { useAddTaskToGroupService } from 'services/tasks/addTaskToGroupService'
import { useFetchTasksByGroup } from 'services/tasks/getTasksByGroupService'
import {
  GetTasksByGroupFiltersByEnums,
  ITask,
  ITaskToGroupDto,
  IUseAddTaskToGroupReturnType,
} from 'services/tasks/getTasksService.interface'
import { columnConfig } from 'pages/tasks/gridConfig'
import { ISelectAll, ISelectedItem } from 'components/common/FilterInterface/filter.interface'
import { PER_PAGE } from 'hooks/users/useUsers'

const useAddTaskToGroup = ({
  groupData,
  closeDrawer,
}: {
  groupData: IGroup
  closeDrawer: () => void
  taskId?: string
}): IUseAddTaskToGroupReturnType => {
  const { t } = useTranslation()
  const [searchValue, setSearchValue] = useState<string>('')
  const [selectedTasks, setSelectedTasks] = useState<string[]>([])
  const [selectedItem, setSelectedItem] = useState<ISelectedItem>({})
  const [selectAll, setSelectAll] = useState<ISelectAll>({})
  const [currentPage, setCurrentPage] = useState<number>(1)
  const [perPage, setPerPage] = useState<number>(PER_PAGE)
  const groupId = groupData.id

  const { addTask } = useAddTaskToGroupService({ groupId })

  const onFormSubmit = async (): Promise<void> => {
    if (!selectedItem || !selectedItem[currentPage]?.length) {
      closeDrawer()
      return
    }

    const taskToGroupData: ITaskToGroupDto = {
      taskIds: selectedItem[currentPage],
      groupId: groupData.id,
    }

    try {
      await addTask(taskToGroupData)
      closeDrawer()
    } catch (error) {
      console.error('Error adding tasks to group:', error)
    }
  }

  const { tasks, totalPages, loading, refetch } = useFetchTasksByGroup({
    searchValue,
    filterBy: GetTasksByGroupFiltersByEnums.NOT_IN_GROUP,
    groupId,
  })

  const handleSearchChange = (e: ChangeEvent<HTMLInputElement>): void => {
    const newSearchValue = e.target.value
    setSearchValue(newSearchValue)
  }

  const selectAllItem = (): void => {
    // Check if tasks are available and not all are selected on the current page
    if (tasks?.length && !selectAll[currentPage]) {
      // Create an array of task IDs, filtering out any falsy values
      const newArr = tasks.map((task: ITask) => task._id || task.id || '').filter(Boolean)
      // Update the selected items for the current page
      setSelectedItem(prev => ({
        ...prev,
        [currentPage]: newArr,
      }))
      // Update the list of selected tasks
      setSelectedTasks(newArr)
      // Mark all items as selected for the current page
      setSelectAll(prev => ({
        ...prev,
        [currentPage]: true,
      }))
    } else {
      // If all items are already selected, deselect all
      setSelectedItem(prev => ({
        ...prev,
        [currentPage]: [],
      }))
      // Clear the list of selected tasks
      setSelectedTasks([])
      // Mark all items as deselected for the current page
      setSelectAll(prev => ({
        ...prev,
        [currentPage]: false,
      }))
    }
  }

  const selectItem = (id?: string): void => {
    if (!id) return
    // Get the list of selected items for the current page
    const selectedItemsOnPage = selectedItem[currentPage] || []
    // Check if the item is already selected
    const isSelected = selectedItemsOnPage.includes(id)
    // Toggle the selection of the item
    const newSelected = isSelected
      ? selectedItemsOnPage.filter(itemId => itemId !== id)
      : [...selectedItemsOnPage, id]
    // Update the selected items for the current page
    setSelectedItem(prev => ({
      ...prev,
      [currentPage]: newSelected,
    }))
    // Update the list of selected tasks
    setSelectedTasks(newSelected)
    // If the item was deselected, ensure 'select all' is marked as false
    if (isSelected) {
      setSelectAll(prev => ({
        ...prev,
        [currentPage]: false,
      }))
    }
  }

  // Check if there are no tasks available
  const hasNoTasks = tasks?.length === 0
  // Determine if the search input is active (not empty)
  const isSearchActive = searchValue.trim() !== ''
  // Show a "no results" message only if there are no tasks and a search is active
  const showNoResultsMessage = hasNoTasks && isSearchActive

  const handleTaskSelection = (taskId: string): void => {
    selectItem(taskId)
    if (selectedTasks.includes(taskId)) {
      setSelectedTasks(selectedTasks.filter(id => id !== taskId))
    } else {
      setSelectedTasks([...selectedTasks, taskId])
    }
  }

  const handlePaginationClick = async (
    _: ChangeEvent<unknown> | null,
    newPage: number,
  ): Promise<void> => {
    try {
      // Update the current page state
      setCurrentPage(newPage)

      // Refetch tasks for the new page
      await refetch({
        searchValue,
        groupId,
        currentPage: newPage,
        perPage,
      })

      // Reset selected items for the new page
      setSelectedItem(prev => ({
        ...prev,
        [newPage]: [],
      }))
    } catch (error) {
      // Log any errors during the refetch process
      console.error('Error during refetch:', error)
    }
  }

  const handleChangeRowsPerPage = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ): void => {
    const newPerPage = parseInt(event.target.value, 10)
    setPerPage(newPerPage)
    refetch({
      searchValue,
      groupId,
      currentPage,
      perPage: newPerPage,
    })
    setSelectedItem
  }

  const gridConfig = columnConfig(handleTaskSelection, selectedTasks, t, false)

  return {
    t,
    showNoResultsMessage,
    searchValue,
    handleSearchChange,
    selectAllItem,
    selectedItem,
    currentPage,
    selectAll,
    selectedTasks,
    tasks,
    selectItem,
    gridConfig,
    onFormSubmit,
    handlePaginationClick,
    perPage,
    handleChangeRowsPerPage,
    loading,
    hasNoTasks,
    totalPages,
  }
}

export default useAddTaskToGroup
